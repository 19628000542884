import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";

export default function NewsDetails() {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [otherArticles, setOtherArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch("https://molex-tools.onrender.com/articles")
      .then((response) => response.json())
      .then((data) => {
        if (data.articles) {
          const mainArticle = data.articles.find((a) => a._id === id);
          setArticle(mainArticle);

          const filteredArticles = data.articles
            .filter((a) => a._id !== id)
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .slice(0, 4);

          setOtherArticles(filteredArticles);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching articles:", error);
        setLoading(false);
      });
  }, [id]);

  if (loading) return <div>Loading article...</div>;
  if (!article) return <div>Article not found.</div>;

  const firstImage = article.images?.[0] || "/default-image.jpg";

  return (
    <div>
      <Header />
      <div className="container mt-4 mt-lg-5 section-padding">
        <Helmet>
          <title>{article.title} - Molex Foundation Africa</title>
          <meta property="og:type" content="article" />
          <meta property="og:title" content={article.title} />
          <meta property="og:description" content={article.content.substring(0, 150)} />
          <meta property="og:image" content={firstImage} />
          <meta property="og:url" content={`https://molexfoundationafrica.org/#/news-detail/${article._id}`} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={article.title} />
          <meta name="twitter:description" content={article.content.substring(0, 150)} />
          <meta name="twitter:image" content={firstImage} />
        </Helmet>

        <div className="row border-bottom mb-4">
          <div className="col-lg-9 col-12">
            <h2 className="mt-2 mb-3">{article.title}</h2>
            <p><strong>Author:</strong> {article.author}</p>
            <p className="text-muted">{new Date(article.createdAt).toLocaleDateString()}</p>
            <div dangerouslySetInnerHTML={{ __html: article.content }} style={{ textAlign: "justify" }}></div>
          </div>

          <div className="col-lg-2 mx-lg-5 col-12">
            {article.images?.length > 0 && (
              <div>
                {article.images.map((img, index) => (
                  <div key={index} className="mb-4">
                    <img src={img} alt={`Article Image ${index}`} loading="lazy" style={{ width: "100%", height: "auto" }} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {otherArticles.length > 0 && (
          <div className="mt-5">
            <h3>Other Articles</h3>
            <div className="row">
              {otherArticles.map((item) => (
                <div key={item._id} className="px-2 col-lg-3">
                  <div className="other-article-card border rounded p-3 h-100 d-flex flex-column">
                    {item.images?.[0] && (
                      <img
                        src={item.images[0]}
                        alt={item.title}
                        className="img-fluid rounded mb-2"
                        style={{ height: "150px", objectFit: "cover", width: "100%" }}
                        loading="lazy"
                      />
                    )}
                    <h6 className="fw-bold">{item.title}</h6>
                    <div className="mt-auto">
                      <Link to={`/news-detail/${item._id}`} className="text-primary">
                        Read more →
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
