import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

export default function News() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 6;

  useEffect(() => {
    fetch("https://molex-tools.onrender.com/articles")
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched articles:", data);
        const sortedArticles = (data.articles || []).sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setArticles(sortedArticles);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching articles:", error);
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading articles...</div>;
  if (!Array.isArray(articles) || articles.length === 0) return <div>No articles available.</div>;

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);
  const totalPages = Math.ceil(articles.length / articlesPerPage);

  return (
    <div>
      <Header />
      <section className="news container mt-5 section-padding">
        <div className="row article-container">
          {currentArticles.map((item, index) => (
            <div key={index} className="col-lg-4 col-md-6 mb-4 d-flex align-items-stretch">
              <div className="article-card border text-center p-3 rounded w-100 d-flex flex-column">
                <img src={item.images?.[0]} alt={item.title} className="img-fluid mb-2" />
                <div className="flex-grow-1">
                  <h5 className="article-title">{item.title}</h5>
                  <p className="text-muted">{new Date(item.createdAt).toLocaleDateString()}</p>
                </div>
                <div className="mt-auto">
                  {item.link ? (
                    <a
                      href={item.link}
                      className="text-danger learn-more"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more <em className="mdi mdi-arrow-right-thin"></em>
                    </a>
                  ) : (
                    <Link to={`/news-detail/${item._id}`} className="text-danger learn-more">
                      Learn more <em className="mdi mdi-arrow-right-thin"></em>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="pagination d-flex justify-content-center mt-4">
          <div onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>&laquo;</div>
          {Array.from({ length: totalPages }, (_, i) => (
            <div 
              key={i + 1} 
              className={currentPage === i + 1 ? "active" : ""}
              onClick={() => setCurrentPage(i + 1)}
            >
              {i + 1}
            </div>
          ))}
          <div onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}>&raquo;</div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
