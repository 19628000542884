import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

const PostArticle = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState(""); // Stores HTML content
  const [author, setAuthor] = useState("");
  const [link, setLink] = useState(""); // Stores external link
  const [images, setImages] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://molex-tools.onrender.com/");
        console.log("GET request successful:", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleImageChange = (e) => {
    setImages(e.target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title || (!content && !link) || !author) {
      setMessage("Title, author, and either content or a link are required.");
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("author", author);
    
    if (content) {
      formData.append("content", content);
    }
    
    if (link) {
      formData.append("link", link);
    }

    for (const file of images) {
      formData.append("images", file);
    }

    try {
      setLoading(true);
      await axios.post("https://molex-tools.onrender.com/articles", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setMessage("Article created successfully!");
    } catch (error) {
      console.log(error);
      setMessage(`Failed to create article: ${error.response?.data?.error || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container col-lg-6 mt-5">
      <h2 className="text-center">Post News/Article</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">Title:</label>
          <input
            type="text"
            className="form-control"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="author" className="form-label">Author:</label>
          <input
            type="text"
            className="form-control"
            id="author"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="link" className="form-label">External Link (optional):</label>
          <input
            type="url"
            className="form-control"
            id="link"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="content" className="form-label">Content (optional if a link is provided):</label>
          <ReactQuill
            theme="snow"
            value={content}
            onChange={setContent}
            placeholder="Write your article here..."
          />
        </div>

        <div className="mb-3">
          <label htmlFor="images" className="form-label">Upload Images:</label>
          <input
            type="file"
            className="form-control"
            id="images"
            multiple
            onChange={handleImageChange}
          />
        </div>

        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? "Submitting..." : "Submit"}
        </button>
      </form>

      {message && <p className="mt-3">{message}</p>}
    </div>
  );
};

export default PostArticle;
